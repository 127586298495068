<template>
  <IForm class="auth-form" v-model="schema" @submit="onSignin">
    <IFormGroup>
      <header>
        <Icon class="logo" name="brand-picsellia" />
        <h1>Login with</h1>
      </header>
    </IFormGroup>

    <IFormGroup>
      <IFormLabel class="label">Username</IFormLabel>
      <IInput class="input" name="username" v-model="username" type="username" />
      <IFormError for="username" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel class="label">Password</IFormLabel>
      <IInput class="input" name="password" v-model="password" type="password" />
      <IFormError for="password" />
    </IFormGroup>

    <IFormGroup>
      <Turnstile ref="turnstile" :site-key="siteKey" v-model="token" />
    </IFormGroup>

    <IFormGroup v-if="error">
      <div class="error">{{ error }}</div>
    </IFormGroup>

    <IFormGroup>
      <IButton type="submit" color="primary" :disabled="disabled || done">
        <ILoader v-if="loading || done" color="primary" class="_margin-right:1/2" />
        Sign In
      </IButton>
    </IFormGroup>

    <IFormGroup>
      <p class="demo-title">
        <span>Don't have an account yet?&nbsp;</span>
        <a href="https://www.picsellia.com/contact" class="demo-title">Request Demo</a>
      </p>
      <p class="demo-title">
        <a href="/forgot-password" class="forget-password-title">Forgot password?</a>
      </p>
    </IFormGroup>
  </IForm>
</template>

<script>
import Icon from "@/components/icons/Icon";
import AuthApi from "@/js/api/AuthApi.js";
import Turnstile from "@/components/auth/Turnstile.vue";
import IButton from "@inkline/inkline/components/IButton/IButton.vue";
import ILoader from "@inkline/inkline/components/ILoader/ILoader.vue";
import IInput from "@inkline/inkline/components/IInput/IInput.vue";
import IForm from "@inkline/inkline/components/IForm/IForm.vue";
import IFormGroup from "@inkline/inkline/components/IFormGroup/IFormGroup.vue";
import IFormLabel from "@inkline/inkline/components/IFormLabel/IFormLabel.vue";
import IFormError from "@inkline/inkline/components/IFormError/IFormError.vue";
import { useForm } from "@inkline/inkline/composables";
import { ref, watch } from "vue";

const page = {
  components: {
    Icon,
    Turnstile,
    IButton,
    ILoader,
    IInput,
    IForm,
    IFormGroup,
    IFormLabel,
    IFormError,
  },
  setup() {
    const { schema } = useForm({
      username: {
        validators: [{ name: "required" }],
      },
      password: {
        validators: [{ name: "required" }],
      },
    });

    const loading = ref(false);
    const username = ref("");
    const password = ref("");
    const error = ref("");
    const disabled = ref(true);
    const done = ref(false);
    const siteKey = ref(process.env.TURNSTILE_SITE_KEY);
    const token = ref("");

    watch(token, () => {
      disabled.value = token.value === "";
    });

    async function onSignin() {
      if (loading.value === true) return;
      loading.value = true;
      error.value = null;
      try {
        await AuthApi.login({ username: username.value, password: password.value, captcha_token: token.value });
        done.value = true;
        location.reload();
      } catch (e) {
        console.error(e);
        if (e.status === 401) {
          error.value = "Invalid username or password";
        } else {
          error.value = e.body.detail[0];
        }
        this.$refs.turnstile.reset();
        password.value = "";
      } finally {
        loading.value = false;
      }
    }

    return { schema, loading, username, password, error, disabled, done, siteKey, token, onSignin };
  },
};

window.pageComponent = page;
export default page;
</script>

<style lang="scss">
@import "@/styles/authPage.scss";

.demo-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 700 !important;
}

.forget-password-title {
  text-align: center;
}
</style>
